import React from 'react'

type Props = {
  height: number
}

const defaultProps = {
  height: 1,
}

export const Divider = ({height}: Props & typeof defaultProps) => {
  const currentHeight = height + `rem`

  return <div style={{width: `100%`, height: currentHeight}} />
}

Divider.defaultProps = defaultProps
