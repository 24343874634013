import React, {ReactNode} from 'react'
import {Head, SidebarMenu} from '..'
import '../../../styles/app.css'
import styles from './default-layout.module.css'

type Props = {
  children: ReactNode
}

export const DefaultLayout = ({children}: Props) => (
  <>
    <Head />
    <div className={styles.layout}>{children}</div>
    <SidebarMenu />
  </>
)
