import {graphql, useStaticQuery} from 'gatsby'
import {useLocation} from '@reach/router'
import React from 'react'
import {Helmet} from 'react-helmet'
import navLinks from '../../../../data/sidebar-menu-links.json'
import {NavLink} from '../../../types'
import siteConfig from '../../../utils/site-config'

export const Head = () => {
  const data = useStaticQuery(graphql`
    query {
      allGhostSettings {
        edges {
          node {
            ...GhostSettingsFields
          }
        }
      }
    }
  `)

  const site = data.allGhostSettings.edges[0].node
  const location = useLocation()

  let pageTitle = `Secret of the Source Code`

  navLinks.forEach((link: NavLink) => {
    if (location.pathname === link.url)
      pageTitle = `${link.title} | ${pageTitle}`
  })

  return (
    <Helmet>
      <html lang={site.lang} />
      <title>{pageTitle}</title>
      <meta property="og:title" content={siteConfig.siteTitleMeta} />
      <meta name="twitter:title" content={siteConfig.siteTitleMeta} />

      <meta name="description" content={siteConfig.siteDescriptionMeta} />
      <meta
        property="og:description"
        content={siteConfig.siteDescriptionMeta}
      />
      <meta
        name="twitter:description"
        content={siteConfig.siteDescriptionMeta}
      />

      <meta name="twitter:card" content="summary_large_image" />
      <meta property="og:image" content={siteConfig.shareImageUrl} />
      <meta name="twitter:image" content={siteConfig.shareImageUrl} />
      <meta property="og:image:width" content={siteConfig.shareImageWidth} />
      <meta property="og:image:height" content={siteConfig.shareImageHeight} />
    </Helmet>
  )
}
