import {useLocation} from '@reach/router'
import React, {useEffect, useState} from 'react'
import {Nullable} from '../../../types'
import {
  closed,
  dark,
  layer,
  light,
  menu,
  menuWrapper,
  open,
} from './burger-menu.module.css'

type Props = {
  openPanel: () => void
  isOpen: boolean
}

export const BurgerMenu = ({isOpen, openPanel}: Props) => {
  const location = useLocation()
  const [color, setColor] = useState<Nullable<string>>(null)
  const [status, setStatus] = useState<Nullable<string>>(null)

  useEffect(() => {
    // depends on Firebase Hosting behavior and control trailing slash config options
    // https://firebase.google.com/docs/hosting/full-config#control_trailing_slashes
    setColor(location.pathname === `/` ? dark : light)
    setStatus(isOpen ? open : closed)
  }, [location.pathname, isOpen])

  if (color === null) return null

  return (
    <div className={menuWrapper}>
      <button className={`${menu} ${color} ${status}`} onClick={openPanel}>
        <div className={layer} />
        <div className={layer} />
        <div className={layer} />
      </button>
    </div>
  )
}
