import {AnimatePresence, motion} from 'framer-motion'
import React, {MutableRefObject, useRef} from 'react'
import {useOnClickOutside} from '../../../utils/hooks'
import styles from './backdrop.module.css'

const variants = {
  visible: {
    opacity: 0.75,
  },
  hidden: {
    opacity: 0,
  },
}

type Props = {
  isVisible: boolean
  removeFromDOM: Function
}

export const Backdrop = ({isVisible, removeFromDOM}: Props) => {
  const ref: MutableRefObject<HTMLDivElement | null> = useRef(null)

  useOnClickOutside(ref, removeFromDOM)

  return (
    <AnimatePresence exitBeforeEnter>
      {isVisible && (
        <motion.div
          ref={ref}
          variants={variants}
          initial="hidden"
          animate="visible"
          exit="hidden"
          transition={{duration: 0.4}}
          className={styles.backdrop}
        />
      )}
    </AnimatePresence>
  )
}
