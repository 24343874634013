import {useLocation} from '@reach/router'
import {motion} from 'framer-motion'
import {Link} from 'gatsby'
import React from 'react'
import navLinks from '../../../../data/sidebar-menu-links.json'
import {MenuLink} from '../menu-link/menu-link'
import styles from './sidebar-panel.module.css'

type Props = {
  closePanel: () => void
  isOpen: boolean
}

// Don't map over the first link, which is the Home page
const sidebarLinks = navLinks.slice(1)

export const SidebarPanel = ({closePanel, isOpen}: Props) => {
  const location = useLocation()

  return (
    <nav
      id="main-menu"
      className={`${styles.nav} ${isOpen ? styles.open : styles.closed}`}
    >
      {location.pathname === `/` ? (
        <img
          src="/images/logo.svg"
          alt="Secret of the Source Code logo"
          className={styles.logo}
        />
      ) : (
        <Link to="/" onClick={closePanel}>
          <motion.img
            src="/images/logo.svg"
            alt="Secret of the Source Code logo"
            className={styles.logo}
            whileHover={{scale: 1.1}}
            whileTap={{scale: 0.9}}
          />
        </Link>
      )}
      <ul className={styles.menu}>
        {sidebarLinks.map(link => (
          <MenuLink link={link} key={link.id} closePanel={closePanel} />
        ))}
      </ul>
    </nav>
  )
}
