import React, {MouseEvent} from 'react'
import {FaqItem} from '../../../types'
import {AccordionItem} from '..'
import styles from './accordion.module.css'

type Props = {
  data: FaqItem[]
  opened: string
  handleSetOpened: (event: MouseEvent<HTMLDivElement>) => void
}

const defaultProps = {
  opened: ``,
}

export const Accordion = ({
  data,
  opened,
  handleSetOpened,
}: Props & typeof defaultProps) => (
  <div className={styles.wrapper}>
    <ul className={styles.list}>
      {data.map(({question, answer, id}) => (
        <li key={id} className={styles.item}>
          <AccordionItem
            question={question}
            answer={answer}
            opened={opened}
            handleToggle={handleSetOpened}
          />
        </li>
      ))}
    </ul>
  </div>
)

Accordion.defaultProps = defaultProps
