import {Link} from 'gatsby'
import React, {ReactNode} from 'react'
import {SotscLogoSimple} from '../../common'
import styles from './header.module.css'

type Props = {
  children?: ReactNode
  logoColors?: {
    primary?: string
    secondary?: string
    shadow?: string
  }
  sectionBg?: {
    color: string
    divider: string
  }
  headerClass: string
  title: string
}

const defaultProps = {
  headerClass: styles.default,
}

export const Header = ({children, headerClass, logoColors, title}: Props) => (
  <section className={`${styles.section} ${headerClass}`}>
    <div className={styles.wrapper}>
      <div className={styles.logoWrapper}>
        <Link to="/">
          <SotscLogoSimple className={styles.logo} {...logoColors} />
        </Link>
      </div>
      <h2 className={styles.title}>{title}</h2>
      {children}
    </div>
  </section>
)

Header.defaultProps = defaultProps
