import {onError, onSubscribeSuccess} from '../utils/toasts'

export async function addToMailingList(email: string) {
  try {
    const response = await fetch(`/add-to-mailing-list`, {
      method: `POST`,
      headers: {
        'Content-Type': `application/json`,
      },
      body: JSON.stringify({email}),
    })

    if (!response.ok) {
      const {message} = await response.json()
      // noinspection ExceptionCaughtLocallyJS
      throw new Error(message)
    }
    await onSubscribeSuccess()
  } catch ({message}) {
    await onError(message)
  }
}
