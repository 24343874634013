import React from 'react'
import {SecondaryNavLink} from '../../../../types'
import styles from './menu-item.module.css'

type Props = {
  item: SecondaryNavLink
}

export const MenuItem = ({item}: Props) => (
  <li className={styles.menuItem}>
    <a
      href={item.uri}
      rel="noopener noreferrer"
      title={item.title}
      aria-label={item.label}
      className={styles.menuLink}
    >
      {item.name}
    </a>
  </li>
)
