import {AnimatePresence, motion} from 'framer-motion'
import React, {MouseEvent} from 'react'
import styles from './accordion-item.module.css'

type Props = {
  question: string
  answer: string
  opened: string
  handleToggle: (event: MouseEvent<HTMLDivElement>) => void
}

const defaultProps = {
  opened: ``,
}

export const AccordionItem = ({
  question,
  answer,
  opened,
  handleToggle,
}: Props & typeof defaultProps) => {
  const isActive = question === opened

  return (
    <>
      <div
        className={styles.itemWrapper}
        data-item={question}
        onClick={handleToggle}
      >
        <div className={styles.itemIcon}>
          <motion.div
            className={styles.iconHorizontal}
            variants={{
              collapsed: {opacity: 1, transform: `rotate(-90deg)`},
              open: {opacity: 0, transform: `rotate(90deg)`},
            }}
            initial="collapsed"
            animate={isActive ? `open` : `collapsed`}
            transition={{duration: 0.2}}
          />
          <motion.div
            className={styles.iconVertical}
            variants={{
              collapsed: {transform: `rotate(-90deg)`},
              open: {transform: `rotate(90deg)`},
            }}
            initial="collapsed"
            animate={isActive ? `open` : `collapsed`}
            transition={{duration: 0.2}}
          />
        </div>
        <h3 className={styles.iconTitle}>{question}</h3>
      </div>
      <AnimatePresence initial={false}>
        {isActive && (
          <motion.div
            className={styles.itemInner}
            initial="collapsed"
            animate="open"
            exit="collapsed"
            variants={{
              open: {opacity: 1, height: `auto`},
              collapsed: {opacity: 0, height: 0},
            }}
            transition={{duration: 0.2}}
          >
            <motion.div
              className={styles.itemContent}
              variants={{
                collapsed: {opacity: 0, transform: `translateY(-1.6rem)`},
                open: {opacity: 1, transform: `translateY(0rem)`},
              }}
              transition={{duration: 0.2}}
            >
              <p
                className={styles.text}
                dangerouslySetInnerHTML={{__html: answer}}
              />
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>
    </>
  )
}

AccordionItem.defaultProps = defaultProps
