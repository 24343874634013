import React, {useState} from 'react'
import {RemoveScroll} from 'react-remove-scroll'
import {Backdrop, BurgerMenu, SidebarPanel} from '..'

export const SidebarMenu = () => {
  const [isVisible, setIsVisible] = useState(false)

  const openPanel = () => {
    setIsVisible(true)
  }

  const closePanel = () => {
    setIsVisible(false)
  }

  return (
    <>
      <BurgerMenu
        isOpen={isVisible}
        openPanel={openPanel}
        aria-controls="main-menu"
      />
      <RemoveScroll enabled={isVisible}>
        <SidebarPanel isOpen={isVisible} closePanel={closePanel} />
      </RemoveScroll>
      <Backdrop isVisible={isVisible} removeFromDOM={closePanel} />
    </>
  )
}
