module.exports = {
  siteUrl: process.env.GHOST_API_URL, // Site domain. Do not include a trailing slash!
  basePath: `/blog`, // Base path for blog location.

  postsPerPage: 12, // Number of posts shown on paginated pages (changes this requires sometimes to delete the cache)

  siteTitleMeta: `Secret of the Source Code`, // This allows an alternative site title for meta data for pages.
  siteDescriptionMeta: `An epic learn-to-code adventure game that builds confidence, creativity, and compassion.`, // This allows an alternative site description for meta data for pages.

  shareImageUrl: `https://firebasestorage.googleapis.com/v0/b/sotsc-platform.appspot.com/o/app%2Fassets%2Fimages%2Fsotsc-characters-in-balloon.jpg?alt=media&token=46b7fd3d-6e4c-4697-ab5c-41f368584587`,
  shareImageWidth: `1000`, // Change to the width of your default share image
  shareImageHeight: `523`, // Change to the height of your default share image

  shortTitle: `SotSC`, // Used for App manifest e.g. Mobile Home Screen
  siteIcon: `favicon.png`, // Logo in /static dir used for SEO, RSS, and App manifest
  backgroundColor: `#e9e9e9`, // Used for Offline Manifest
  themeColor: `#15171A`, // Used for Offline Manifest
}
