import {RefObject, useEffect} from 'react'

export const useOnClickOutside = (
  ref: RefObject<HTMLElement>,
  handler: Function,
) => {
  useEffect(() => {
    const listener: EventListener = (event: Event) => {
      if (ref.current && ref.current.contains(event.target as Node)) {
        handler(event)
      }
    }

    document.addEventListener(`mousedown`, listener)

    return () => {
      document.removeEventListener(`mousedown`, listener)
    }
  }, [ref, handler])
}
