import {motion} from 'framer-motion'
import React from 'react'
import {Partner} from '../../../../types'
import styles from './partner-item.module.css'

type Props = {
  partner: Partner
}

export const PartnerItem = ({partner}: Props) => (
  <motion.li
    whileHover={{scale: 1.1}}
    whileTap={{scale: 0.9}}
    className={styles[partner.className]}
  >
    <a
      href={partner.url}
      target="_blank"
      rel="noopener noreferrer"
      title={partner.name}
      aria-label={`Visit ${partner.name} website`}
    >
      <img
        className={styles[partner.className]}
        src={`/images/${partner.logo}`}
        alt={`${partner.name} logo`}
      />
    </a>
  </motion.li>
)
