import React, {ChangeEvent, FormEvent, useState} from 'react'
import {addToMailingList} from '../../../services/addToMailingList'
import styles from './subscription-form.module.css'

type Props = {
  variant: string
}

const defaultProps = {
  variant: `default`,
}

export const SubscriptionForm = ({variant}: Props & typeof defaultProps) => {
  const variantClassname = styles?.[variant] ?? styles.default

  const [email, setEmail] = useState(``)
  const [isInputDisabled, setIsInputDisabled] = useState(false)

  const isButtonDisabled = !email.trim() || isInputDisabled

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value)
  }

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    setIsInputDisabled(true)
    await addToMailingList(email)
    setIsInputDisabled(false)
    setEmail(``)
  }

  return (
    <form className={styles.form} onSubmit={handleSubmit} noValidate>
      <input
        type="email"
        className={`${styles.input} ${variantClassname}`}
        placeholder="your email address"
        value={email}
        onChange={handleChange}
        disabled={isInputDisabled}
      />
      <button
        type="submit"
        className={`${styles.button} ${variantClassname}`}
        disabled={isButtonDisabled}
      >
        Subscribe
      </button>
    </form>
  )
}

SubscriptionForm.defaultProps = defaultProps
