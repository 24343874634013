import React from 'react'
import {SocialMediaItem} from '../..'
import styles from './social-media-list.module.css'
import socialMediaMenu from '../../../../../data/social-media.json'

export const SocialMediaList = () => (
  <ul className={styles.menu}>
    {socialMediaMenu.map(item => (
      <SocialMediaItem item={item} key={item.id} />
    ))}
  </ul>
)
