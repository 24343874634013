import Swal from 'sweetalert2'

const Toast = Swal.mixin({
  toast: true,
  position: `bottom-end`,
  showConfirmButton: false,
  timer: 3000,
  timerProgressBar: true,
  onOpen: toast => {
    toast.addEventListener(`mouseenter`, Swal.stopTimer)
    toast.addEventListener(`mouseleave`, Swal.resumeTimer)
  },
})

export const onSubscribeSuccess = async (message?: string) =>
  Toast.fire({
    icon: `success`,
    title: message ? message : `You have successfully subscribed!`,
  })

export const onError = async (message: string) =>
  Toast.fire({
    icon: `error`,
    title: message ? message : `Something went wrong. Please, try again later!`,
  })
