import {useLocation} from '@reach/router'
import {motion} from 'framer-motion'
import {Link} from 'gatsby'
import React from 'react'
import {NavLink} from '../../../types'
import styles from './menu-link.module.css'

type Props = {
  closePanel: () => void
  link: NavLink
}

export const MenuLink = ({closePanel, link}: Props) => {
  const location = useLocation()
  let linkClass = styles.link;

  if (link.title.includes("Play")) {
    linkClass += " " + styles.play
  }

  if (location.pathname.endsWith(link.url)) {
    return <li className={styles.disabled}>{link.title}</li>
  }

  return (
    <motion.li
      className={styles.linkItem}
      whileHover={{scale: 1.1}}
      whileTap={{scale: 0.9}}
    >
      <Link to={link.url} className={linkClass} onClick={closePanel}>
        {link.title}
      </Link>
    </motion.li>
  )
}
