import { motion } from 'framer-motion'
import React from 'react'
import {Menu, PartnerList, SocialMediaList} from '..'
import styles from './footer.module.css'

export const Footer = () => (
  <section className={styles.section}>
    <div className={styles.wrapper}>
      <header className={styles.header}>
        <h2 className={styles.heading}>A Project By:</h2>
        <motion.image whileHover={{scale: 1.1}} whileTap={{scale: 0.9}}>
          <a
            href="https://littlerobotfriends.com"
            target="_blank"
            rel="noopener noreferrer"
            title="Little Robot Friends"
            aria-label={`Visit Little Robot Friends website`}
          >
            <img
              src="/images/lrf-line-logo.svg"
              alt="Little Robot Friends logo"
              className={styles.logoLRF}
            />
          </a>
        </motion.image>
      </header>
      <div className={styles.supporters}>
        <h3 className={styles.subHeading}>Supported By:</h3>
      </div>
      <div className={styles.logoWrapper}>
        <PartnerList />
      </div>
      <hr className={styles.divider} />
      <div className={styles.socialMediaWrapper}>
        <SocialMediaList />
      </div>
      <p className={styles.copyright}>
        Copyright &copy; {new Date().getFullYear()}, Little Robot Friends Inc.
        All Rights Reserved.
      </p>
    </div>
  </section>
)
