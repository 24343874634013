import {motion} from 'framer-motion'
import React from 'react'
import {SocialMediaLink} from '../../../../types'
import styles from './social-media-item.module.css'

type Props = {
  item: SocialMediaLink
}

export const SocialMediaItem = ({item}: Props) => (
  <motion.li
    className={styles.menuItem}
    whileHover={{scale: 1.1}}
    whileTap={{scale: 0.9}}
  >
    <a
      href={item.url}
      rel="noopener noreferrer"
      title={item.title}
      target="_blank"
      aria-label={item.title}
      className={styles.menuLink}
    >
      <img
        src={`/images/${item.icon}`}
        alt={item.title}
        className={styles.socialIcon}
      />
    </a>
  </motion.li>
)
