import React from 'react'
import partners from '../../../../../data/partner-list.json'
import {PartnerItem} from '../..'
import styles from './partner-list.module.css'

export const PartnerList = () => (
  <ul className={styles.logoList}>
    {partners.map(partner => (
      <PartnerItem partner={partner} key={partner.id} />
    ))}
  </ul>
)
